import React from 'react'

import { Icon } from '@iconify/react'
import githubFilled from '@iconify/icons-ant-design/github-filled';
import linkedinFilled from '@iconify/icons-ant-design/linkedin-filled';
import webFilled from '@iconify/icons-mdi/web'

export default function LinkCard({
  web,
  webName,
  github,
  figma,
}) {

  let links = [
    {
      name: webName || "Web",
      icon: webFilled,
      url: web
    }, {
      name: "Github",
      icon: githubFilled,
      url: github
    }, {
      name: "Figma",
      icon: linkedinFilled,
      url: figma
    }
  ]

  links = links.filter(item => item.url)

  return (
    <div>
      <ul>
        {links.map(({ name, icon, url }) =>
          <li className="block mb-4 last:mb-0">
            <a href={url} target="_blank" rel="noreferrer">
              <Icon icon={icon} height={24} className="mr-4 inline-block" /> {name}
            </a>
          </li>
        )}
      </ul>
    </div>
  )
}