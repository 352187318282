import React from 'react'
import MainTemplate from './MainTemplate'
import styled from 'styled-components'
import { Link } from 'gatsby'

const NavigationSidebar = styled.div`
  min-width: 240px;
  width: 240px;
`

export default function ProjectTemplate(props) {
  return (
    <MainTemplate>
      <div className="flex">
        <div className="w-full">
          {props.children}
        </div>
        <div className="hidden md:block pl-12">
          <NavigationSidebar className="sticky top-8">
            <h4 className="mb-4">Navigations</h4>
            <ul className="">
              {props.navigationItem
                ? props.navigationItem.map(nav =>
                  <li className="hover:font-bold mb-2">
                    <Link to={`#${nav.toLowerCase()}`}>
                      {nav}
                    </Link>
                  </li>
                ) :
                (
                  <>
                    <li className="hover:font-bold mb-2">
                      <Link to="#overview">
                        Overview
                      </Link>
                    </li>
                    <li className="hover:font-bold mb-2">
                      <Link to="#responsibility">
                        Responsibility
                      </Link>
                    </li>
                    <li className="hover:font-bold mb-2">
                      <Link to="#technology">
                        Technology
                      </Link>
                    </li>
                    <li className="hover:font-bold mb-2">
                      <Link to="#conclusion">
                        Conclusion
                      </Link>
                    </li>
                  </>
                )
              }
            </ul>
          </NavigationSidebar>
        </div>
      </div>
    </MainTemplate>
  )
}