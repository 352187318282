import React from 'react'
import LinkCard from '../../components/Cards/LinkCard'
import Seo from '../../components/Partials/Seo'
import ProjectTemplate from '../../components/Template/ProjectTemplate'

export default function CryptoGalaxy() {
  return (
    <ProjectTemplate>
      <Seo
        title="Crypto Galaxy - Project"
        image="/images/projects/cryptogalaxy-cover.png"
      />
      <h1>Crypto Galaxy - Web Slicing</h1>
      <div className="mb-4">
        <img className="w-full mb-4" src="/images/projects/cryptogalaxy-cover.png" alt="Dicka Ismaji Personal Website" />
        <div className="p-4 border-2 border-red-600 rounded-xl">
          <LinkCard
            web={"https://cryptogalaxy.netlify.app"}
            webName={"Crypto Galaxy"}
            github={"https://github.com/dicka88/crypto-galaxy"}
          />
        </div>
      </div>
      <section id="about" className="pt-4">
        <h2 className="font-bold">Overview</h2>
        <p>Thanks to <a className="text-red-400" href="https://dribbble.com/calvinnadeak" target="_blank" rel="noreferrer">Calvin Andreas</a> to feel free to share their resource design for me to slice into web apps</p>
        <p>Cryptogalaxy is landing page of Mining Crypto coins</p>
      </section>
      <section id="overview" className="pt-4">
        <h2 className="font-bold">Overview</h2>
        <p>According to Google Chrome Lighthouse, when i test website perfomance the result is below</p>
      </section>
      <section id="technology" className="pt-4">
        <h2 className="font-bold">Technology</h2>
        <p>React, Next JS</p>
      </section>
    </ProjectTemplate>
  )
}